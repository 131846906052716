/* You can add global styles to this file, and also import other style files */
@import "_variables.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.view-card {
  margin-bottom: 10px;

  .row {
    display: flex;
    padding: 10px;

    .header {
      font-weight: bold;
      margin-right: 15px;
    }
  }
}

.page-header {
  padding-top: 10px;
}
.mat-toolbar-single-row {
  background: none;
}

.big-button {
  width: 100%;
  height: 50px;
  background-color: $primary-color;
  color: white;
}

.clickable-text {
  color: darkgrey;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    color: gray;
  }

  &.under-form-field {
    margin-top: -20px;
    z-index: 100;
  }

  &.right{
    text-align: right;
  }

}

//style for the delete icon in the image 
.image-container{
  position: relative;
  max-width: 500px;
  img{
    display: block;
    max-width: 500px;
  }
  .delete-icon {
    color: red;
    position: absolute; 
    top:0; 
  }
}
//style for offers, vendors, categories tables
.data-table {
  width: 100%;
  background: none;
  border-collapse: separate !important;
  border-spacing: 0 15px !important;

  tr {
    th {
      background-color: #e0e0e0;
      padding: 24px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
    td {
      background-color: white;
      padding: 24px;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
  }


  // style for all the images in the rows
  .row-img {
    height: 50px;
    width: 50px;
  }

  // rounded corners for each row
  tr:first-child th:first-child,
  td:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-left: 1px solid $border-color;
  }
  // first row, last col [th & td]
  tr:first-child th:last-child,
  td:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-right: 1px solid $border-color;
  }
}

// styling for toggle button
.toggle-status {
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: green;
  }
  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #00b33c;
  }
}

//styles for offer, vendor, category form
.form-page-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  .back-button {
    position: absolute;
    top: 0;
    left: 0;
    background-color:  white;
    color: $primary-color;
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px;
  background-color: white;
  border: 0.1rem solid;
  border-color: lightgray;
}
.form-title {
  color: $primary-color;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}

//style for radio buttons color
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  //opacity: 0.5 !important;     /*click effect color change*/
  background-color: $primary-color !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $primary-color !important; /*inner circle color change*/
}
::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $primary-color !important; /*outer ring color change*/
}

mat-radio-button {
  margin-top: 6px;
  margin-right: 12px;
}

.remove-icon {
  color: darkgray;
  &:hover {
    cursor: pointer;
    color: indianred;
  }
}

.under-form-field {
  margin-top: -15px;
  z-index: 5;
}

.small-text {
  font-size: 10pt; 
  color: darkgrey;
 }

 .field-label {
    font-size: 10pt;
    color: $primary-color;
    margin-bottom: 6px;
    text-transform: uppercase;

    &.bold{
      font-weight: bold;
    }
 }

 mat-form-field {
   width: 100%;
 }

 .mat-form-field-appearance-outline .mat-form-field-outline {
  color: darkgrey;
}


//style for all the view dialogs 

.view-dialog {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.dialog-container {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 30px;
  background-color: white;
  border: 0.1rem solid;
  border-color: lightgray;
}
//view dialog title
.view-title{
  font-size: 14pt;
  color:$primary-color;
  margin: 15px;
  font-weight: bold;
  text-align: center;
}
    

//style for images in the view dialogs
.view-img{
  height: 50px;
  width: 50px;
}

//lables in view dialogs
.view-label {
  font-size: 12pt;
  color: $primary-color;
  margin-bottom: 15px;
  font-weight: bold;
  }
  //data in view dialogs
.view-data{
  margin-bottom: 15px;
}

